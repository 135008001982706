@charset "utf-8";
/*===========================
 =  Table of CSS
  	1. General code
		1.1. Typography
		1.2. Button
		1.3. Space margins and padding
		1.4. Form
		1.5. Table
		1.6. Accordion
		1.7. Section-background-color & sectino-heading
		1.8. Tabs
		1.9. parallex-background
		1.10. Modal
	2. Header
	3. Intro
	4. Latest-Event-Sermons
		4.1. Event-List
		4.2. Sermons-List
	5. Causes
	6. Footer
	7. Inner-Intro
	8. Sermons
	9. Sermon-Detail
	10. Events
	11. Event-Detail
	12. Blog
	13. Blog-Detail
		13.1 Post-style
		13.2. Sidebar
		13.3. Comments
	14. Contact-Us
	15. Donation
	16. About-Us
	17. Pegination
===========================*/
/*====================
	Google Fonts
==========================*/
@import url("https://fonts.googleapis.com/css?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap");
/*====================
	1. General code
==========================*/

body {
	font-family: 'Noto Sans', sans-serif;
	margin: 0px;
	padding: 0px;
	font-size: 15px;
	line-height: 28px;
	overflow-x: hidden;
	font-weight: 400;
	color: #000000;
}
/*----------------------
	1.1. Typography
------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #010101;
	margin: 0 auto 20px;
	font-family: 'Marcellus', sans-serif;
	font-weight: 700;
	text-transform: capitalize;
}
h1 {
	font-size: 36px;
}
h2 {
	font-size: 30px;
}
h3 {
	font-size: 26px;
}
h4 {
	font-size:24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
 p {
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 25px;
	font-family: 'Noto Sans', sans-serif;
}
a {
	transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	-webkit-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	text-decoration: none;
	color: #010101;
}
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
	color: #010101;
}
ul,
ol {
	margin: 0 0 20px;
}
ul li,
ol li {
	margin: 0 0 10px;
}
blockquote {
	background: #fff;
	position: relative;
	padding: 0 0 0 70px;
	margin: 0 auto 30px;
}
blockquote::after {
	position: absolute;
	top: 2px;
	left: 5px;
	content: "";
	background: url(../images/quote_icon.png) no-repeat center;
	width: 40px;
	height: 40px;
}
blockquote p {
	color: #010101;
	font-size: 18px;
	font-weight: 600;
}
blockquote h6 {
	color: #F17024;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 15px;
}
img {
	max-width: 100%;
	width: auto;
	height: auto;
}
.none{
	display: none;
}
/*-----------------
	1.2. Button
-------------------------*/

.btn {
	background: #F17024;
	border: medium none;
	border-radius: 0px;
	color: #ffffff;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 30px;
	height: auto;
	margin: auto;
	letter-spacing: 0.5px;
	padding: 12px 32px;
	transition: all 0.3s linear 0s;
	-moz-transition: all 0.3s linear 0s;
	-o-transition: all 0.3s linear 0s;
	-webkit-transition: all 0.3s linear 0s;
	-ms-transition: all 0.3s linear 0s;
}
.dark-btn {
	background: #F17024;
	color: #fff;
}
.btn.btn-lg {
	font-size: 14px;
	line-height: 30px;
	padding: 16px 45px;
}
.btn.btn-sm {
	font-size: 14px;
	padding: 8px 22px;
}
.btn:hover,
.btn:focus {
	color: #ffffff;
	outline: none;
	background: #010101;
	box-shadow: none;
}
.dark-btn:hover {
	background: #010101;
}
.btn-link {
	font-weight: 800;
	font-size: 15px;
	color: #010101;
}
.btn.outline-btn {
	background: none;
	color: #deb668;
	border: #deb668 solid 3px;
	letter-spacing: 4px;
	padding: 15px 50px;
	font-weight: 700;
}
.btn.outline-btn:hover {
	color: #fff;
}
.tk-btn-link:hover {
	color: #F17024;
}
/*-------------------------------------
	1.3. Space margins and padding
------------------------------------------------*/

.padding_none {
	padding: 0px;
}
.padding_top_20 {
	padding-top: 20px;
}
.padding_50px {
	padding: 50px 0;
}
.padding_4x4_30 {
	padding: 30px;
}
.padding_4x4_40 {
	padding: 40px;
}
.padding_right {
	padding-right: 60px;
}
.padding_left {
	padding-left: 60px;
}
.space-20 {
	width: 100%;
	height: 20px;
	clear: both;
}
.space-30 {
	width: 100%;
	height: 30px;
	clear: both;
}
.space-40 {
	width: 100%;
	height: 40px;
	clear: both;
}
.space-60 {
	width: 100%;
	height: 60px;
	clear: both;
}
.space-80 {
	height: 80px;
	width: 100%;
}
.margin-btm-20 {
	margin-bottom: 20px;
	clear: both;
}
.margin-top-20 {
	margin-top: 20px;
	clear: both;
}
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-30{
	margin-top: 30px;
}
.margin-top-40 {
	margin-top: 40px;
	clear: both;
}
.margin-btm-40 {
	margin-bottom: 40px;
	clear: both;
}
.margin-top-60 {
	margin-top: 60px;
	clear: both;
}
.margin-btm-60 {
	margin-bottom: 60px;
	clear: both;
}
.margin_60 {
	margin: 60px auto;
}
.margin-none {
	margin: 0px;
}
/*-----------------
	1.4. Form
---------------------------*/

.form-group {
	margin-bottom: 25px;
	position: relative;
}
.form-label {
	color: #022147;
	font-size: 16px;
	font-weight: 400;
	margin: 0 auto 8px;
}
.form-control {
	background: #ffffff;
	border: 1px solid #dddddd;
	border-radius: 0;
	box-shadow: none;
	color: #131313;
	font-size: 13px;
	min-height: 44px;
	line-height: 33px;
	padding: 10px 15px;
	font-weight: 500;
	margin: 0 0 15px;
}
textarea.form-control {
	padding: 15px 20px;
}
.form-control:hover {
	box-shadow: none;
	outline: none
}
.form-control:focus {
	box-shadow: none;
	border-color: #F17024;
	outline: none;
	border: 1px solid #F17024;
}
.select {
	position: relative;
}
.select select {
	appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
}
.select::after {
	color: #878787;
	content: "\f107";
	cursor: pointer;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	font-size: 15px;
	padding: 12px 0;
	pointer-events: none;
	position: absolute;
	right: 15px;
	top: 0;
}
.form-control option {
	padding: 10px;
}
.control-label {
	color: #555;
	font-size: 15px;
	font-weight: 700;
}
.radio label,
.checkbox label {
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	padding-left: 30px;
	position: relative;
}
.radio input[type=radio],
.checkbox input[type=checkbox] {
	display: none;
}
.radio label:before {
	background-color: transparent;
	border-style: solid;
	border-width: 1px;
	border-color: #cacaca;
	border-radius: 50%;
	content: "";
	display: inline-block;
	height: 20px;
	left: 0;
	top: 2px;
	position: absolute;
	width: 20px;
}
.checkbox label::before {
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #111111;
	content: "";
	display: inline-block;
	height: 16px;
	left: 0;
	position: absolute;
	top: 4px;
	width: 16px;
}
.radio input[type=radio]:checked + label:before {
	content: "\2022";
	font-size: 30px;
	text-align: center;
	line-height: 18px;
	color: #010101;
}
.checkbox input[type=checkbox]:checked + label:before {
	content: "\2713";
	font-size: 12px;
	text-align: center;
	line-height: 14px;
}
.black_input .form-control {
	background: #222;
	border-radius: 3px;
	color: #fff;
	border: #222 solid 1px;
	font-size: 17px;
}
.checkbox,
.radio {
	padding-top: 5px;
}
.email_input input:focus {
	outline: 0;
}
/*-----------------
	1.5. Table
---------------------------*/

table {
	margin: 0 0 30px;
	width: 100%;
}
table th,
table td {
	border: 1px solid #cccccc;
	padding: 15px;
	padding: 18px;
}
table th img,
table td img {
	max-width: 100%;
}
table thead {
	background: #eee;
}
table thead th,
table thead td {
	text-transform: uppercase;
	font-weight: 900;
	color: #111;
}
/*-----------------
	1.6. Accordion
---------------------------*/

.panel-title {
	position: relative;
}
.panel-title a {
	display: block;
	padding: 28px 50px 28px 0;
	position: relative;
}
.panel-title a::after {
	position: absolute;
	top: 33px;
	right: 0;
	background: #b7b7b7;
	height: 2px;
	width: 16px;
	content: "";
}
.panel-title a.collapsed::before {
	position: absolute;
	top: 26px;
	right: 7px;
	background: #b7b7b7;
	height: 16px;
	width: 2px;
	content: "";
}
.panel-title {
	margin: 0 auto;
}
.panel.panel-default:first-child {
	border-top: #e8e7e7 solid 1px;
}
.panel.panel-default {
	border-bottom: #e8e7e7 solid 1px;
}
/*-----------------------------------------------------------
	1.7. Section-background-color & sectino-heading
-------------------------------------------------------------------*/

.secondary-bg {
	background: #fff5ed;
}
.primary-bg {
	background: #010101;
}
.gray_bg {
	background: #f8f8f8;
}
.section-padding {
	padding: 80px 0;
}
.section-header {
	margin: 0 0 auto;
	max-width: 520px;
	padding-bottom: 30px;
}
.section-header p{
	margin-bottom: 0;
}
.section-header-center {
	margin: 0 auto;
	max-width: 520px;
	padding-bottom: 25px;
}
.section-header-center h3,
.section-header h3 {
    font-size: 30px;
}
.about-us-content h2{
	font-size:36px;
}
.about_company .nav-tabs.testi-nav-tabs li:last-child a{
	margin-right: 10px;
}
.text-custom-primary {
	color: #F17024;
}
.text-custom-light-secondary {
	color: #fdb58b;
}
.z_index {
	z-index: 2;
}
.divider {
	border-top: #ebebeb solid 1px;
}
.white_text,
.white_text p,
.white_text h1,
.white_text h2,
.white_text h3,
.white_text h4,
.white_text h5,
.white_text h6 {
	color: #fff;
}
.fs-36{
	font-size: 36px;
}

/*--------------------
	1.8. Tabs
------------------------------*/

.nav.nav-tabs {
	border: medium none;
}
.nav-tabs > li a {
	background: #000000 none repeat scroll 0 0;
	border-radius: 0;
	color: #ffffff;
	font-size: 20px;
	font-weight: 400;
	margin-right: 5px;
	padding: 14px 26px;
	text-align: center;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li a:hover,
.nav-tabs > li a:focus {
	color: #ffffff;
	background: #c7335b;
}
.nav-tabs > li {
	margin-bottom: 0;
}
/*--------------------------------------------
	1.9. Parallex-background
---------------------------------------------------*/

.parallex-bg {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
/*------------------------
	1.10. Modal
--------------------------------*/

.modal-dialog {
	margin: 20px auto;
	max-width: 90%;
	width: 500px;
}
.modal-content {
	padding: 0 32px 22px;
}
.modal-header {
	padding: 15px 0;
	margin-bottom: 25px;
}
.modal-header h3 {
	margin: 15px auto;
}
.modal-body {
	padding: 10px 0;
}
.modal .modal-header .close {
	background: #000000 none repeat scroll 0 0;
	border-radius: 50%;
	color: #ffffff;
	font-size: 17px;
	height: 31px;
	line-height: 30px;
	margin-top: 5px;
	opacity: 1;
	position: absolute;
	right: -10px;
	text-align: center;
	text-shadow: none;
	top: -20px;
	width: 31px;
}
label.error {
	color: #ff0000;
	display: block;
	font-size: 13px;
	font-weight: normal;
	text-align: left;
}
/*================
	 2. Header
===========================*/

#header {
	left: 0;
	right: 0;
  background-color: #000000;
	position: relative;
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	z-index: 22;
}
#header.nav-stacked {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
#header.sticky {
	position: fixed;
	top: 0;
}
@-webkit-keyframes fadeHeaderInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
	}
}
@-moz-keyframes fadeHeaderInDown {
	0% {
		opacity: 0;
		-moz-transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-moz-transform: translate3d(0, 0, 0);
	}
}
@-o-keyframes fadeHeaderInDown {
	0% {
		opacity: 0;
		-o-transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		-o-transform: translate3d(0, 0, 0);
	}
}
@-webkit-keyframes ripple {
	0% {
		-webkit-transform: scale(1);
	}
	75% {
		-webkit-transform: scale(1.75);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(2);
		opacity: 0;
	}
}
@keyframes ripple {
	0% {
		transform: scale(1);
	}
	75% {
		transform: scale(1.55);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}
@keyframes fadeHeaderInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
#header.sticky,
.fadeHeaderInDown {
	-webkit-animation-name: fadeHeaderInDown;
	-moz-animation-name: fadeHeaderInDown;
	-o-animation-name: fadeHeaderInDown;
	animation-name: fadeHeaderInDown;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 22;
	padding: 4px 0px;
	background-color: #010101;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#header:not(.is-alternate) {
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-ms-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
}
.header_top {
	padding: 10px 0;
	display: table;
	width: 100%;
}
header.sticky .header_top {
	display: none;
}
.header_top p,
.header_top .select_language {
	float: left;
	color: #a5afbb;
	margin: 7px auto;
	font-size: 14px;
}
.select_language select {
	background: none;
	color: #a5afbb;
	border: 0;
	appearance: none;
	-o-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding: 12px 5px;
}
.collapse.navbar-collapse {
	width: auto;
	flex-basis: auto;
	text-align: right;
	flex-grow: inherit;
}
.navbar-nav {
	display: block;
}
.navbar-nav li {
	display: inline-block;
	vertical-align: top;
	margin: 0 auto;
}
.navbar.navbar-default {
	padding: 5px 0;
}
.navbar-nav li a {
	display: block;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-family: 'Noto Sans', sans-serif;
	padding: 13px 15px;
	margin: 0 auto;
}
.navbar-nav li a:hover,
.navbar-nav li a.active {
	color: #f17024;
}
.navbar-nav .btn {
	font-size: 700;
	padding: 13px 32px;
}
.navbar-nav .btn:hover {
	color: #fff;
}
#header .logo-big {
	top: 0;
	position: fixed;
	left: 44%;
	background: #F17024;
	color: #ffffff;
	height: 170px;
	width: 170px;
}
#header.sticky .logo-big {
	height: 90px;
	width: 160px;
}
#header .logo-mobil {
	display: none;
}
#header.sticky .nav-border {
	border: 0;
}
.nav-border {
	border: 1px solid rgba(255, 255, 255, 0.5);
	padding: 10px;
}
.navbar-nav li:last-child a {
	padding-right: 0px;
}
/*-----------
	sub-menu
-------------*/

.navbar .navbar-nav li.dropdown > a {
	padding-right: 28px;
}
.navbar .navbar-nav li.dropdown > a::after {
	content: "\f107";
	font-family: "Font Awesome 5 Free";
	position: absolute;
	right: 9px;
	top: 12px;
	font-weight: 900;
}
ul.sub-menu {
	background: #fff none repeat scroll 0 0;
	display: none;
	list-style: outside none none;
	min-width: 200px;
	padding: 0;
	position: absolute;
	left: 0;
	top: 100%;
	width: auto;
	z-index: 9999;
	text-align: left;
	box-shadow: rgba(0, 0, 0, 0.12) 0 0 15px;
}
ul.sub-menu ul.sub-menu {
	left: 100%;
	top: 0;
	display: none;
}
.navbar .navbar-nav li:hover > ul.sub-menu,
.navbar .navbar-nav li ul.sub-menu:hover > ul.sub-menu {
	display: block;
}
.navbar ul li ul.sub-menu li {
	float: none;
	margin: 0;
	display: block;
}
.navbar ul li ul.sub-menu li a {
	padding: 10px 17px;
	color: #282828;
	border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
}
.navbar ul li ul.sub-menu li a:hover {
	color: #f17024;
}
#menu_slide {
	display: none;
}
.mobile-menu ul.sub-menu {
	background: #ff8f4e none repeat scroll 0 0;
}
/*================
	 3. Intro
===========================*/

#intro {
	position: relative;
}
#intro .item {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.intro_text {
	max-width: 750px;
	text-align: left;
	padding: 180px 0;
	position: relative;
	z-index: 9;
}
.video_intro_text {
	padding: 250px 0;
	margin: 0 auto;
	text-align: center;
}
.tl-video-header .video_intro_text p {
	margin: 0 auto 40px;
}
.intro_text h1 {
	font-size: 65px;
	text-transform: uppercase;
}
.intro_text p {
	font-size: 16px;
	max-width: 70%;
	margin-bottom: 40px;
}
.call-action-section .call-content a:hover,
.header_top .top-btn li a.templezen-top-btn:hover,
.intro_text a.templezen-top-btn:hover,
.causes_info a:hover {
	color: #ffffff;
	background-color: #fdb58b;
}
#intro .owl-nav div {
	width: 50px;
	height: 50px;
	background: #F17024;
	border-radius: 0;
}
#intro .owl-nav div:hover {
	background-color: #e65500;
}
#intro .owl-nav {
	left: 2%;
	width: 96%;
}
#intro .owl-nav div::after {
	line-height: 48px;
}
/* Video HEader */

.tl-video-header {
	position: relative;
	background-color: black;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.tl-video-header .overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
}
.tl-video-header video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
/*==============================
	4. Latest-Event-Sermons
=====================================*/

.latest_event_sermons .box_wrap {
	padding: 30px;
	text-align: left;
	margin: 0 auto;
}
.latest_event_sermons .tl-slider-arrow {
	display: flex;
}
.latest_event_sermons .tl-slider-arrow .tl-left-arrow,
.latest_event_sermons .tl-slider-arrow .tl-right-arrow {
	background-color: #F17024;
	padding: 10px 15px;
	color: #fff;
	cursor: pointer;
}
.latest_event_sermons .tl-slider-arrow .tl-left-arrow:hover,
.latest_event_sermons .tl-slider-arrow .tl-right-arrow:hover {
	background-color: #010101;
}
.event-slider {
	overflow: hidden;
}
.subtitle {
	color: #F17024;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.latest_event_sermons .event_info {
	float: none;
	width: 100%;
	position: relative;
	padding: 10px 0 15px 110px;
}
.latest_event_sermons h4 a,
.latest_event_sermons h6 a {
	color: #010101;
}
.latest_event_sermons h4 a:hover,
.latest_event_sermons h6 a:hover {
	color: #F17024;
}
.latest_event_sermons .event_list.event-list-alignemt .event_date {
	top: 24%;
}
.latest_event_sermons .event-list-alignemt .event_date {
	left: 76%;
	width: 80px;
}
.latest_event_sermons .event_date {
	background: no-repeat;
	position: absolute;
	top: 10px;
	left: 0;
	width: 80px;
	padding: 18px 0 0;
	color: #010101;
	line-height: 22px;
}
.right-sermon .event_date {
	right: 0;
}
.latest_event_sermons {
	position: relative;
	margin-top: -55px;
	z-index: 1;
}
.next_sermons .sermons_inside {
	text-align: left;
	padding: 20px 0;
	float: none;
	width: auto;
	clear: both;
}
.next_sermons .sermons_inside ul li {
	margin: 0 15px 0 0;
}
/* New Styles */

#tl .video-bg {
	background: url(../images/video-bg-img.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	border: 0px;
	position: relative;
}
/* Trips */
.trip-box-wrapper{
	box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 20px;
	position: relative;
	overflow: hidden;
}
.trip-box-img{
	position: relative;
  overflow: hidden;
}
.trip-box-img img{
    transition: .3s ease-in-out;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
}
.trip-box-wrapper .trip-box-content{
    padding: 20px;
    background: #fff;
}

.trip-box-content .list-class{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}
.trip-box-content .list-class li:last-child{
	margin-bottom: 0;
}
.trip-box-content .ratings{
	margin-bottom: 20px;
}
.ratings{
	display: flex;
	justify-content: space-between;
}
.rating-stars span.rate {
	color: gold;
}
.trip-location{
	display: block;
	color: #f17024;
}
.trip-box-wrapper .list-class i{
	color: #F17024;
	margin-right: 8px;
}
.rating-stars span{
	color: #cccccc;
	cursor: pointer;
}
.trip-date{
	position: absolute;
	right: 10px;
	top: 10px;
	display: inline-block;
	padding: 5px 20px;
	background-color: rgba(241, 112, 36, 0.92);
	color: #fff;
	border-radius: 2px;
	z-index: 9;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
/*--------------------
	4.1. Event-List
---------------------------*/

.event_list ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
}
.event_list ul li {
	margin: 0 auto 20px;
}
.event_list ul li:last-child {
	margin: 0 auto;
}
.event_list ul .event_info ul {
	margin: 0 auto;
}
.event_list ul .event_info ul li {
	margin: 0 auto;
}
.event_list h6 {
	margin: 0 auto 12px;
}
.latest_event_sermons .event_list .event_date {
	top: 24px;
}
.latest_event_sermons .event_list .event_info {
	padding: 25px 25px 25px 136px;
	border: #e8e7e7 solid 1px;
	border-radius: 6px;
}
.latest_event_sermons .event_list.event-list-alignemt .event_info {
	padding: 25px 136px 25px 25px;
	border: #e8e7e7 solid 1px;
	border-radius: 6px;
}
.latest_event_sermons .event_list .event_date {
	width: 106px;
	padding: 20px 0 10px;
	border-right: #e8e7e7 solid 1px;
}
.latest_event_sermons .event_list.event-list-alignemt .event_date {
	width: 106px;
	padding: 10px 0 10px;
	border-left: #e8e7e7 solid 1px;
	border-right: 0px;
}
.heading {
	overflow: hidden;
	margin: 0 auto 50px;
}
.heading h3 {
	display: inline-block;
	font-size: 30px;
	margin: 6px auto;
}
.heading .btn-sm {
	padding: 6px 15px;
}
/*---------------------
	4.2. Sermons-List
---------------------------*/

.panel-body .sermons_inside {
	text-align: left;
	padding: 15px 0 50px;
	padding-right: 0;
	float: none;
	width: 100%;
}
.panel-body .sermons_inside li {
	margin: 0 15px 0 0;
}
.player-controls.scrubber {
	float: left;
	width: 70%;
	background: #faf4e8;
	padding: 8px 20px;
	margin: 0 auto;
}
.audio-player {
	overflow: hidden;
	padding: 16px 0 0;
}
.audio-player .player-controls progress {
	width: 76%;
}
.audio-player .player-controls progress[value] {
	-webkit-appearance: none;
	appearance: none;
	background-color: #e7e3db;
	color: blue;
	height: 9px;
	border: 0;
}
.small,
small {
	font-size: 80%;
	font-weight: 400;
	color: #010101;
	font-size: 14px;
}
.audio-player .player-controls progress[value]::-webkit-progress-bar {
	background-color: #e7e3db;
	border-radius: 2px;
	border: 0;
	color: #deb668;
}
.audio-player .player-controls progress::-webkit-progress-value {
	background-color: #deb668;
}
.audio-player .player-controls p {
	font-size: 1.6rem;
}
.audio-player #play-btn {
	width: 55px;
	height: 55px;
	margin: 0 15px 0 0;
	background: #fff3ea;
	text-align: center;
	color: #010101;
	line-height: 55px;
	cursor: pointer;
	float: left;
}
.next_prev {
	width: 55px;
	height: 55px;
	float: right;
	background: #fff3ea;
	text-align: center;
	color: #010101;
	line-height: 55px;
	cursor: pointer;
}
.audio-player #play-btn .fa-pause,
.audio-player #play-btn.pause .fa-play {
	display: none;
}
.audio-player #play-btn.pause .fa-pause {
	display: inline-block;
}
.player-controls.scrubber {
	float: left;
	width: 70%;
	background: #fff3ea;
	padding: 13px 17px;
	margin-right: 15px;
	text-align: center;
}
.player-controls.scrubber .fa {
	color: #010101;
}
.about_company .nav-tabs.testi-nav-tabs li a {
	background: #f6e0ce none repeat scroll 0 0;
	border-radius: 0;
	color: #010101;
	font-size: 16px;
	font-weight: 400;
	margin-right: 10px;
	padding: 9px 30px;
	text-align: center;
}
.nav-tabs.testi-nav-tabs .nav-link.active,
.nav-tabs.testi-nav-tabs a.nav-link:focus {
	color: #ffffff;
	background-color: #F17024;
	border-color: #F17024;
}
.nav-tabs.testi-nav-tabs a.nav-link:hover {
	color: #fff;
	background-color: #fdb58b;
	border-color: #fdb58b;
}
/* Tabs 2 */
.sermon-box .sermons_meta{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.sermon-box .sermons_meta i{
	color: #F17024;
  margin: 0 6px 0 0;
}
.sermon-box  .sermons_meta a {
    color: #555b62;
    text-decoration: underline;
}
.sermon-box .sermons_inside {
	   text-align: center;
	   margin-bottom: 25px;
		 padding: 0;
}
.sermon-box .sermons_inside {
    float: none;
    width: 100%;
}
/*================
	5. Causes
======================*/

#causes {
	background: url(../images/home-page/home-inside-banner.png) no-repeat center / cover;
	background-attachment: fixed;
}
.causes_info {
	margin: 0 auto;
	max-width: 580px;
	text-align: center;
}
.causes_info .h__set h6 {
	text-transform: uppercase;
	color: #fdb58b;
	font-size: 16px;
	margin: 0 auto 50px;
}
.causes_chart {
	overflow: hidden;
	margin: 25px auto 50px;
}
.causes_chart p {
	display: inline-block;
	vertical-align: middle;
	margin: 30px auto;
}
.chart {
	display: inline-block;
	vertical-align: middle;
	margin: 0 20px;
}
.owl-nav {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 1px;
	left: 0;
	width: 100%;
}
.owl-nav div {
	position: relative;
	width: 40px;
	height: 40px;
	font-size: 0px;
	float: left;
}
.owl-nav div.owl-next {
	float: right;
}
.owl-nav div::after {
	position: absolute;
	top: 0;
	left: 0;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	width: 100%;
	content: "\f104";
	color: #fff;
	font-size: 24px;
	height: 100%;
	text-align: center;
	line-height: 40px;
}
.owl-nav div.owl-next:after {
	content: "\f105";
}
.owl-nav div:hover:after {
	opacity: 1;
}
/*-----------------------
	 Donate img Section
------------------------*/

.donation-img-section .select_amount li {
	padding: 8px 12px;
	margin-right: 8px;
}
.donation-img-section .select_amount {
	margin: 0 auto 25px;
}
.donation-img-section .img-width-100 img {
	width: 100%;
}
/*-----------------------
	 Call To Action Section
------------------------*/
.custom-text{
	text-align: left;
}
.text-custom-align-right{
	text-align: right!important;
}
.call-action-section .call-content {
	max-width: 620px;
}
.call-action-section {
	background: url(../images/home-page/holi-banner-1.jpg) no-repeat center / cover;
	background-attachment: fixed;
}
.call-action-section .call-content ul {
	margin-left: 0px;
	padding-left: 0px;
}
.call-action-section .call-content ul li {
	display: inline-block;
	font-size: 1em;
	list-style-type: none;
	padding: 1em;
	text-transform: uppercase;
	color: #fff;
}
.call-action-section .call-content ul li span {
	display: block;
	font-size: 4.5rem;
}
/*================
	 6. Footer
===========================*/
footer{
	background-color: #010101;
	color: #fff;
}
.footer_top{
	border-bottom: 1px solid #343434;
}
.footer_bottom {
	padding: 20px 0;
	border-top: #343434 solid 1px;
}
.footer_bottom p {
	color: #ffffff;
	margin: 0 auto;
}
.footer_links {
	text-align: right;
}
.footer_links a {
	display: inline-block;
	color: #ffffff;
	margin: 0 0 0 20px;
}
.top_widget {
	padding-top: 25px;
	border-right: rgba(255, 255, 255, 0.2) solid 1px;
	padding-bottom: 25px;
}
.top_widget:last-child {
	border: 0;
}
.follow_us ul {
	margin: 0 auto;
	padding: 0;
	list-style: none;
	text-align: left;
}
.follow_us ul li {
	display: inline-block;
	vertical-align: middle;
	margin: 0px 0 0px 22px;
}
.follow_us ul li a {
	color: #fff;
	font-size: 15px;
	padding: 7px 2px;
	display: block;
}
.follow_us ul li:first-child {
	margin-left: 0px;
}
.header_top .top-btn {
	float: right;
	margin-bottom: 0px;
}
.header_top .top-btn li {
	margin-bottom: 0px;
}
.header_top .top-btn li i {
	color: #ffffff;
	font-size: 16px;
	background: #21212100;
	padding: 5px;
	border-radius: 0px;
	margin-right: 5px;
}
.header_top .top-btn li a {
	color: #fff;
}
.header_top .top-btn li a.btn {
	color: #ffffff;
	border:1px solid #f17024;
}
.header_top .top-btn li a:hover {
	color: #f17024;
}
.header_top .top-btn li a.dark-btn:hover{
	background-color: transparent;
	border:1px solid #f17024;
}
.follow_us ul li a:hover,
.follow_us ul li a:focus,
.footer_links a:hover,
.footer_nav li a:hover,
.footer_nav li a.active,
.footer_widget p a:hover {
	color: #F17024;
}
#intro::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.5;
}
.newsletter {
	padding: 0 20px;
	overflow: hidden;
}
.email_input {
	float: left;
	position: relative;
	width: 72%;
}
.email_input::after {
	position: absolute;
  top: 10px;
  left: 0;
  content: "\f0e0";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
}
.email_input input {
	background: no-repeat;
	border: 0;
	color: #fff;
	padding: 9px 25px;
}
.newsletter button {
	padding: 9px 6px;
	background: none;
	border: 0;
	color: #fff;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.5px;
	font-size: 14px;
	float: right;
	cursor: pointer;
}
.footer_widget {
	margin: 80px auto;
	align-self: center;
}
.footer_widget .footer_nav ul li:nth-child(5),
.footer_widget .footer_nav ul li:nth-child(6),
.footer_widget .widget_inner p:last-child{
	margin-bottom: 0;
}
.footer_widget:last-child {
	border: 0;
}
.footer_widget:last-child .widget_inner {
	padding-right: 0;
}
.footer_widget:first-child .widget_inner {
	padding-left: 0;
}
.widget_inner {
	padding: 0 20px;
}
.footer_widget h5 {
	color: #ffffff;
	margin: 0 auto 20px;
}
.footer_widget p {
	color: #ffffff;
	margin: 0 auto 10px;
}
.footer_widget p a {
	color: #ffffff;
}
.footer_widget ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
	overflow: hidden;
	position: relative;
}
.footer_nav li {
	display: inline-block;
	width: 48%;
	vertical-align: top;
}
.footer_nav li a {
	color: #ffffff;
}
.video_post {
	position: relative;
	width: 100%;
}
.video_post_icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	background-color: #fff;
	color: white;
	font-size: 16px;
	padding: 0px 20px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
	border-radius: 50px;
	line-height: 3;
}
.hm-list.hm-instagram {
	display: flex;
	justify-content: center;
	margin-bottom: 0;
	padding-left: 0px;
}
.hm-list.hm-instagram li {
	overflow: hidden;
}
.hm-list.hm-instagram li {
	position: relative;
	margin-right: 0;
	margin-bottom: 0px;
}
.hm-list li {
	display: inline-block;
	margin-right: 15px;
}
.hm-list.hm-instagram li img {
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.hm-list.hm-instagram li:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
.instagram_img {
	position: relative;
}
.instagram_img li {
	float: left;
	width: 25%;
	margin: 0 auto;
}
.instagram_img li a {
	background: #000;
	display: block;
}
.instagram_img li a img {
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
}
.instagram_img a img:hover {
	opacity: 0.7;
}
.insta_url {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 50px;
	height: 50px;
	background: #F17024;
	color: #fff;
	font-size: 20px;
	border-radius: 50%;
	text-align: center;
	line-height: 50px;
	transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}
.insta_url:hover {
	color: #fff;
	background: #003b4a;
}
#back-top {
	text-align: center;
}
#back-top a {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 0px;
	text-align: center;
	line-height: 34px;
	border: #f17024 solid 1px;
	color: #f17024;
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-ms-transition-duration: 0.3s;
	font-size: 22px;
	margin: 0 auto;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 0 16px 1px;
}
#back-top a:hover {
	background: #F17024;
	color: #fff;
}
.sa-video-blog-popup {
	position: relative;
	transition: 0.3s;
}
.thumb img {
	width: 100%;
}
.sa-video-blog-popup .popup-youtube {
	top: 50%;
	bottom: 0;
	left: 50%;
	right: 0;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.8);
	height: 50px;
	width: 50px;
	border-radius: 50%;
	line-height: 54px;
	text-align: center;
	color: #F17024;
	font-size: 20px;
	margin: -25px 0 0 -25px;
	box-shadow: 2.5px 4.33px 30px 0px rgba(0, 0, 0, 0.6);
}
.sa-video-blog-popup .popup-youtube {
	height: 80px;
	width: 80px;
	line-height: 75px;
	margin: -40px 0 0 -40px;
	font-size: 30px;
	box-shadow: 2.5px 4.33px 30px 0px rgba(0, 0, 0, 0.6);
}
.widget_inner .video_icon a::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	transform: scale(1.3);
	z-index: 1;
	width: 100%;
	height: 100%;
	-webkit-animation-name: pulseInOut;
	-moz-animation-name: pulseInOut;
	-o-animation-name: pulseInOut;
	animation-name: pulseInOut;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	background: #fff;
}
.video_icon .popup-youtube i {
	margin-left: 0px;
	text-align: center;
  vertical-align: middle;
}
@-webkit-keyframes pulseInOut {
	0% {
		opacity: 0.3;
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		transform: scale(1.3);
	}
	100% {
		opacity: 0.3;
		transform: scale(0.3);
	}
}
@-moz-keyframes pulseInOut {
	0% {
		opacity: 0.3;
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		transform: scale(1.3);
	}
	100% {
		opacity: 0.3;
		transform: scale(0.3);
	}
}
@-o-keyframes pulseInOut {
	0% {
		opacity: 0.3;
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		transform: scale(1.3);
	}
	100% {
		opacity: 0.3;
		transform: scale(0.3);
	}
}
@keyframes pulseInOut {
	0% {
		transform: scale(0.3);
		opacity: 0.7;
	}
	50% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}
.video-wrap-section .video_wrap .video_icon {
	 padding: 9.2% 0;
}
/*=====================
	7. Inner-Intro
=============================*/

#inner_intro {
	position: relative;
	background-color: #4c4c4c;
	background-image: url(../images/home-banner/banner-02.png);
	background-position: center;
	background-size: cover;
}
.inner_wp {
	padding: 75px 0;
}
#inner_intro.section-padding {
	padding: 20px 0;
}
.breadcrumb {
	background: none;
	text-align: center;
	padding: 0;
	margin: 0 auto;
	display: block;
}
.breadcrumb ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
}
.breadcrumb ul li.breadcrumb-item {
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	color: #fff;
}
.breadcrumb ul li a {
	color: #010101;
}
.breadcrumb ul li a:hover {
	color: #f17024;
}
.breadcrumb-item + .breadcrumb-item::before {
	content: "\f105";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	color: #fff;
	padding: 0 13px 0 2px;
	vertical-align: middle;
}
/*===============
	8. Sermons
======================*/

.sermons_wrap {
	padding: 30px;
	border: #f2f2f2 solid 1px;
	display: table;
	width: 100%;
	margin: 0 auto 30px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 20px;
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
}
.sermons_wrap:hover {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 30px;
}
.sermons_img {
	float: left;
	width: 21%;
}
.sermons_img a img{
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
}
.sermons_info_wrap {
	float: right;
	width: 76%;
	padding: 30px 0;
	overflow: hidden;
}
.sermons_info,
.sermons_inside {
	float: left;
	width: 50%;
}
.sermons_inside {
	text-align: right;
	padding-top: 18px;
	padding-right: 15px;
}
ul.sermons_meta {
	padding: 0;
	list-style: none;
	margin: 0 auto;
	overflow: hidden;
}
.sermons_info h5 {
	margin: 0 auto 17px;
}
.sermons_meta li {
	float: left;
	margin: 0 28px 0 0;
}
.sermons_meta li i {
	color: #F17024;
	margin: 0 6px 0 0;
}
.sermons_meta li a {
	color: #555b62;
	text-decoration: underline;
}
.sermons_meta li a:hover {
	color: #f17024;
}
.sermons_inside ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
}
.sermons_inside ul li {
	display: inline-block;
	vertical-align: middle;
	color: #aaadb0;
	font-size: 19px;
	margin: 0px 0 0 20px;
}
.sermons_inside ul li a {
	display: block;
	color: #aaadb0;
	height: 52px;
	width: 52px;
	line-height: 45px;
	text-align: center;
	border: #e8e7e7 solid 2px;
	border-radius: 6px;
}
.filter_wrap {
	margin: 0 auto 40px;
}
.layout-switcher {
	text-align: right;
}
.layout-switcher a {
	color: #b1b1b1;
	font-size: 22px;
	margin-left: 40px;
}
.sermons_info ul li a:hover,
.sermons_info h5 a:hover,
.sermons_inside ul li a:hover,
.layout-switcher a.active,
.layout-switcher a:hover {
	color: #f17024;
}
.sermons_inside ul li a:hover {
	border-color: #f17024;
}
.select_arrow {
	position: relative;
	display: inline-block;
}
.select_arrow:after {
	position: absolute;
	top: 10px;
	right: -5px;
	content: "\f107";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	color: #010101;
}
.select_arrow .form-control {
	appearance: none;
	padding-right: 30px;
	-moz-appearance: none;
	-o-appearance: none;
	-webkit-appearance: none;
	width: auto;
	border: #ccc solid 1px;
	padding: 0 15px;
	cursor: pointer;
	width: 120%;
}
.pagination_wrap {
	padding: 15px 0 0;
}
.pagination_wrap p {
	margin: 0 auto;
}
.pagination_wrap .pagination {
	text-align: right;
	margin: 0 auto;
	padding: 0;
	display: block;
}
.pagination li {
	display: inline-block;
	margin: 0;
}
.pagination li a,
.page-item:last-child .page-link {
	border-color: #fff;
	color: #555b62;
	border-radius: 0;
	padding: 7px 13px;
}
.pagination li a:hover,
.page-item:last-child .page-link:hover,
.page-item.active .page-link {
	color: #010101;
	border: #010101 solid 1px;
	border-radius: 0;
	background: #fff;
}
/*===================
	9. Sermon-Detail
==========================*/

.sermons_list h6 a {
	color: #010101;
}
.sermons_list h6 a:hover {
	color: #F17024;
}
.sidebar_title,
.sermons_list {
	border-bottom: #c1b7b7 solid 1px;
	padding: 25px;
}
.sidebar_title h6 {
	text-transform: uppercase;
	margin: 0 auto;
	color: #010101;
	font-weight: 700;
}
.post_detail .sermons_inside {
	position: absolute;
	text-align: center;
	bottom: 0;
	padding: 0;
	left: 0;
	width: 100%;
	background: #F17024;
}
.post_detail .sermons_inside ul {
	margin: 0 auto;
}
.post_detail .sermons_inside ul li {
	float: left;
	width: 25%;
	margin: 0 auto;
	border-right: rgba(255, 255, 255, 0.2) solid 1px;
}
.post_detail .sermons_inside ul li:last-child {
	border: 0;
}
.post_detail .sermons_inside ul li a {
	color: #fff;
	display: block;
	padding: 25px 20px;
	border: 0;
	height: auto;
	width: auto;
	line-height: inherit;
}
.post_detail .sermons_inside ul li a:hover {
	color: #010101;
}
/*==============
	10. Events
======================*/

.events_wrap {
	margin: 0 auto 50px;
	overflow: hidden;
}
.event_date {
	float: left;
	width: 11%;
	text-align: center;
	font-family: 'Noto Sans', sans-serif;
	background: #010101;
	color: #fff;
	padding: 33px 5px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
}
.event_date span {
	color: #F17024;
	display: block;
	font-size: 40px;
	margin: 0 auto 12px;
}
.event_img {
	float: left;
	width: 37%;
	margin-left: 2.7%;
}
.event_info {
	float: right;
	width: 47.4%;
}
.event_info p {
	margin: 0 auto 20px;
}
.event_info ul {
	padding: 0;
	margin: 0 auto 20px;
	list-style: none;
}
.event_info ul li {
	margin: 0 auto 5px;
}
.event_info ul li i {
	color: #F17024;
	margin-right: 8px;
}
.event_info h4 a:hover {
	color: #F17024;
}
.event-content h6 {
	text-transform: uppercase;
	color: #fdb58b;
	font-size: 16px;
	margin: 0 auto 50px;
}
.timer.event-timer {
	margin: 0 auto;
	text-align: center;
	width: calc(100% - 50%);
	float: none;
}
/*=====================
	 11. Event-Detail
===========================*/

.sidebar_widgets .event_venue ul {
	padding: 28px 25px;
	margin: 0 auto;
	list-style: none;
}
.event_venue ul li {
	padding: 2px 0;
	overflow: hidden;
}
.event_venue ul li span {
	float: left;
	display: block;
	width: 43%;
	font-weight: 600;
	color: #010101;
}
.event_timer {
	position: absolute;
	text-align: center;
	bottom: 0;
	padding: 0;
	left: 0;
	width: 100%;
	background: #F17024;
}
.date {
	float: left;
	width: 14%;
	text-align: center;
	font-family: 'Noto Sans', sans-serif;
	color: #fff;
	padding: 13px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	border-right: rgba(255, 255, 255, 0.2) solid 1px;
}
.date span {
	color: #010101;
	display: block;
	font-size: 30px;
	margin: 0 auto 2px;
}
.timer {
	float: right;
	width: calc(100% - 17%);
	text-align: left;
}

.event-timer .countdown-period{
	background: rgba(241, 112, 36, 0.65);
	margin-right: 20px;
	width: 20%;
	box-shadow: 0 3px 16px rgba(0, 0, 0, .25);
}
.event-timer .countdown-period:last-child{
	margin-right: 0;
}

.event-timer #countdown{
	margin: 0 auto;
	text-align: center;
	display: flex;
	justify-content: center;
}

.black-btn{
	background-color: #000000;
	color: #ffffff;
}

.countdown-period {
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	width: 25%;
	float: left;
	padding: 12px 15px;
}
.countdown-period span {
	display: block;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 300;
}
.venu_map iframe {
	width: 100%;
	height: 200px;
	border: 0;
}
.team_url a{
	vertical-align: middle;
}
/*==============
	 12. Blog
====================*/
.blog_info,
.sermon-box {
	margin: 0px;
	background: #fff;
	position: relative;
	z-index: 1;
	padding: 30px;
	border: #f2f2f2 solid 1px;
	text-align: center;
}
.blog_info h5 a {
	color: #010101;
}
.blog_info h5 a:hover {
	color: #F17024;
}

#blog.latest_blog .owl-nav div::after {
	background-color: #010101;
}
#blog.latest_blog .owl-nav div:hover::after {
	background-color: #F17024;
}
/* post-date */
.post_detail .blog-post-date,
.blog_wrap .blog-post-date{
    position: absolute;
    top: 0;
    right: 20px;
		z-index: 1;
}
.post_detail .blog-post-date,
.blog_wrap .blog-post-date{
	display: inline-block;
  background-color: #f17024;
  min-width: 60px;
  padding: 10px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}
.post_detail .blog-post-date strong,
.blog_wrap .blog-post-date strong {
    display: block;
    margin-bottom: 5px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
}
.post_detail .post_meta span a{
	color: #000000;
}
.post_detail .post_meta span a:hover{
	color: #f17024;
}
.post_meta{
	margin-bottom: 10px;
	text-transform: capitalize;
  color: #000000;
}
.post_meta span a{
	color: #797979;
}
.post_meta span a:hover{
	color: #000000;
}
.post_meta span:after {
	content: "/";
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 12px;
    width: 2px;
}
.post_meta span:last-child:after {
  content: none;
}
/*==================
	13. Blog-Detail
========================*/
/*--------------------
	13.1. Post-style
-------------------------*/

.post_detail .post_img {
	margin: 0 auto 40px;
	position: relative;
}
.post_meta ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
	overflow: hidden;
	margin: 0 auto 30px;
}
.post_meta ul li {
	float: left;
	margin: 0 20px 0 0;
}
.post_meta li i {
	margin-right: 8px;
	color: #F17024;
}
.post_detail ul,
.post_detail ol {
	padding: 0;
	margin: 0 auto 30px;
	list-style: none;
	overflow: hidden;
}
.tags_share {
	border-top: #ebebeb solid 1px;
	border-bottom: #ebebeb solid 1px;
	margin: 40px auto 80px;
}
.psot_share {
	border-right: #ebebeb solid 1px;
}
.psot_tags,
.psot_share {
	padding: 12px 0;
}
.psot_tags span,
.psot_share span {
	font-size: 16px;
	color: #010101;
	padding-right: 10px;
}
.psot_tags a,
.psot_share a {
    color: #555b62;
}
.psot_tags a:hover,
.psot_share a:hover {
	color: #F17024;
}
.psot_share a {
	margin-right: 15px;
}
.comment-form label{
	font-weight: 600;
}
.event_timer .date span{
	 color: #ffffff;
}
.blog-slider .owl-nav{
	opacity: 0;
}

.blog-slider:hover .owl-nav{
	opacity: 1;
}

.blog-slider .owl-nav{
    transition: all 0.2s cubic-bezier(.83, .8, .11, .64) 0s;
}

/*--------------------
	13.2. Sidebar
--------------------------*/

.sidebar_wrap {
	border: #f2f2f2 solid 1px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
}
.widget_title {
	padding: 0 0 8px;
}
.sidebar_widgets {
	padding: 25px 28px;
}
.widget_title h6 {
	position: relative;
}
.widget_title h6::after {
	position: absolute;
	left: -28px;
	top: 0;
	height: 100%;
	content: "";
	background: #010101;
	width: 5px;
}
.sidebar_widgets ul {
	padding: 0;
	margin: 0 auto;
	list-style: none;
}
.sidebar_nav ul li a {
	color: #555b62;
	font-size: 15px;
	display: block;
	padding: 5px 30px 5px 0;
	position: relative;
	border-bottom: #fff dashed 1px;
}
.sidebar_nav ul li a:after {
	position: absolute;
	top: 5px;
	right: 0;
	content: "\f105";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	opacity: 0;
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
}
.sidebar_nav ul li a:hover {
	color: #F17024;
	border-color: #F17024;
}
.sidebar_widgets .sidebar_nav ul li.btn-sidebar:hover {
	background-color: #F17024;
	color: #ffffff;
	border-color: #F17024;
}
.sidebar_widgets .sidebar_nav ul li.btn-sidebar a:hover {
	color: #ffffff;
}
.sidebar_nav ul li a:hover:after {
	opacity: 1;
}
.recent_post li {
	position: relative;
	padding-left: 100px;
	overflow: hidden;
	margin: 0 auto 20px;
}
.post_thumb {
	position: absolute;
	top: 0;
	left: 0;
	height: 80px;
	width: 80px;
	background: #000;
}
.post_thumb img {
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	;
}
.post_thumb img:hover {
	opacity: 0.7;
}
.recent_post h6 {
	font-size: 16px;
	margin: 12px 0 6px;
}
.recent_post h6 a {
	color: #010101;
}
.recent_post p {
	color: #707070;
	font-size: 14px;
	margin: 0 auto 15px;
}
.recent_post h6 a:hover {
	color: #F17024;
}
.tag_cloud {
	overflow: hidden;
}
.tag_cloud a {
	display: inline-block;
	padding: 6px 17px;
	border: #ebebeb solid 1px;
	background: #f9f9f9;
	color: #555b62;
	font-size: 14px;
	margin: 0 10px 10px 0;
}
.tag_cloud a:hover {
	background: #010101;
	color: #fff !important;
	border-color: #010101;
}
.search {
	position: relative;
}
.search::after {
	position: absolute;
	top: 10px;
	right: 20px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f002";
	opacity: 0.5;
}
.tl-ads img {
	width: 100%;
}
/*--------------------
	13.3. Comments
-------------------------*/

.comment-respond,
.all_comments {
	border: #f2f2f2 solid 1px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
	padding: 30px;
}
.all_comments {
	margin: 0 auto 80px;
}
.comment-avatar {
	width: 70px;
	border-radius: 50%;
	margin-right: 20px;
	float: left;
	overflow: hidden;
}
.comment-content {
	float: right;
	width: calc(100% - 90px);
	border-bottom: #eee solid 1px;
	padding-bottom: 30px;
}
.comment-author a {
	color: #282828;
	font-weight: 700;
	margin-right: 12px;
}
.comment-time {
	color: #aaaaaa;
	font-size: 14px;
}
.comment-meta {
	margin: 0 auto 10px;
}
.comment-text p {
	line-height: 24px;
	margin: 0 auto 15px;
}
.reply {
	text-transform: uppercase;
	letter-spacing: 0.2px;
}
.comment.the-comment {
	overflow: hidden;
	margin: 0 auto 40px;
}
.commentlist .comment ol {
	padding-left: 90px;
	margin: 0 auto;
}
.commentlist .comment ol li:last-child .comment-content {
	padding-bottom: 0;
	border: 0;
}
.focus-input {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}
.focus-input::after {
	font-size: 15px;
	color: #555b62;
	line-height: 1.2;
	content: attr(data-placeholder);
	display: block;
	width: 100%;
	position: absolute;
	top: 16px;
	left: 0px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}
.form-control:focus + .focus-input::after {
	top: -15px;
	color: #aaaaaa;
	font-size: 13px;
}
/*====================
	14. Contact-Us
==========================*/

.contact_wrap {
	margin: 0 auto;
	max-width: 950px;
	border: #f2f2f2 solid 1px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
}
.contact_info {
	background: #010101;
	padding: 45px;
	color: #fff;
	position: relative;
}
.contact_info:after {
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	width: 100%;
	height: 100%;
	background: url(../images/comment_icon.png) no-repeat center;
	content: "";
}
.contact_info * {
	z-index: 1;
	position: relative;
}
.contact_info p span {
	display: block;
}
.box_heading {
	margin: 0 0 100px;
}
.box_heading h4 {
	display: inline-block;
	position: relative;
	color: #fff !important;
	margin: 0 auto;
	font-size: 24px;
	font-weight: 500;
	line-height: 30px;
	padding-bottom: 15px;
}
.box_heading h4 span {
	display: block;
	font-size: 15px;
	font-weight: 400;
}
.box_heading h4:after {
	position: absolute;
	bottom: 0;
	left: 0;
	content: "";
	border-bottom: #fff solid 2px;
	width: 40px;
}
.form_wrap {
	padding: 45px;
}
.map_wrap {
	height: 450px;
	padding: 15px;
	border: #f2f2f2 solid 1px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
	border-radius: 3px;
	margin: 0 auto 100px;
}
.map_wrap iframe {
	width: 100%;
	height: 100%;
	border: 0;
}
/*================
	15. Donation
======================*/

.donation_form .form-control {
	border-radius: 4px;
	padding: 5px 10px;
}
.select_amount {
	padding: 0;
	margin: 0 auto 10px;
}
.select_amount li {
	display: inline-block;
	vertical-align: top;
	padding: 8px 22px;
	border: #f6e0ce solid 1px;
	background-color: #f6e0ce;
	border-radius: 3px;
	cursor: pointer;
	margin-right: 12px;
}
.select_amount li:hover,
.select_amount li:focus {
	background-color: #fdb58b;
	border: 1px solid #fdb58b;
	color: #ffffff;
}
.select_amount li.active {
	background-color: #F17024;
	border: 1px solid #F17024;
	color: #fff;
}
.input-group-text {
	color: #fff;
	padding: 5px 20px;
	font-size: 22px;
	background-color: #F17024;
	border: 1px solid #F17024;
}
.donation_form .input-group {
	max-width: 270px;
	margin-bottom: 20px;
}
.donation_form .input-group .form-control {
	margin: 0px;
}
/*=================
	16. About-Us
======================*/

.about_us {
	margin: 0 auto;
	max-width: 730px;
	text-align: center;
	/* background: url(../images/templezen-icon.png) no-repeat center bottom; */
	margin: 0 auto 70px;
}
.box_wrap {
	border: #f2f2f2 solid 1px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
	padding: 30px;
	margin: 0 auto 0px;
	text-align: center;
	background: #fff;
}
.box_wrap.next_event,
.box_wrap.next_event img {
	transition: 0.3s;
}
.box_wrap.next_sermons:hover,
.box_wrap.video-bg:hover {
	box-shadow: 0 3px 16px rgba(0, 0, 0, .25);
}
.box_wrap h6,
.box_wrap p {
	margin: 0 auto 6px;
}
.team_img {
	text-align: center;
	overflow: hidden;
	width: 130px;
	height: 130px;
	border-radius: 50%;
	margin: 0 auto 12px;
	position: relative;
}
.icon {
	margin: 0 auto 12px;
}
.team_url {
	position: absolute;
	top: 8px;
	left: 8px;
	right: 8px;
	bottom: 8px;
	border-radius: 50%;
	background: #F17024;
	text-align: center;
	line-height: 105px;
	font-size: 30px;
	color: #deb668;
	cursor: pointer;
	transition-duration: 0.3s;
	-moz-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
}
.team_img:hover .team_url {
	transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
}
.features{
	text-align: center;
	padding-top: 80px;
}
.features_wrap {
	position: relative;
}
.about-us-content p:last-child{
	margin-bottom: 0;
}

.features_wrap.features-after-none {
	padding: 30px;
	transition: 0.5s;
	cursor: pointer;
}
.features_wrap.features-after-none .f-f-icon i {
	background: #fff;
	padding: 17px 17px;
	border: 1px solid rgba(40, 40, 40, 0.01);
	color: #010101;
	border-radius: 100%;
	margin-bottom: 24px;
	box-shadow: 0px 3px 3px 0px rgba(40, 40, 40, 0.2);
}
.features_wrap.features-after-none .f-f-icon img {
	margin-bottom: 20px;
}
.features_wrap.features-after-none:hover {
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.09);
	margin-top: -10px;
	transition: 0.5s;
}
.features_wrap:hover h6 {
	color: #010101 !important;
}
.features_wrap.features-after-none:after {
	content: none;
}
.f__icon {
	position: absolute;
	top: -2px;
	left: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	border: #e5e5e5 solid 1px;
	color: #010101;
	font-size: 18px;
	line-height: 40px;
}
.video_wrap {
	position: relative;
}
.video_icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	text-align: center;
	padding: 128px 0;
}
.video_icon a {
	position: relative;
}
.video_icon a:before {
	animation: ripple 2s linear infinite;
}
.video_icon a:after {
	animation: ripple 2.5s linear infinite;
}
.video_icon a:before,
.video_icon a:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.5);
}
.bg-white-light {
	background-color: #f2f2f2;
}
.our_testimonials .video_icon {
	padding: 125px 0px 125px 60px;
}
.video_icon a {
	display: block;
	width: 80px;
	height: 80px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 77px;
	color: #F17024;
	font-size: 32px;
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 15px;
	margin: 0 auto;
}
#testimonials {
	background: #003b4a;
	padding: 200px 0 50px 40px;
	color: #fff;
	position: relative;
}
#testimonials:after {
	background: #003b4a;
	position: absolute;
	top: 0;
	height: 100%;
	right: 0px;
	width: 2000px;
	margin-right: -2000px;
	content: "";
}
.img_wrap {
	position: relative;
	z-index: 1;
	margin-bottom: -115px;
	margin-left: 40px;
}
#testimonials p {
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	margin: 0 auto 40px;
}
#testimonials h6 {
	margin: 0 auto;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
}
#testimonials .owl-carousel:after {
	position: absolute;
	top: -50px;
	left: 0px;
	width: 44px;
	height: 30px;
	/* background: url(../images/quote_icon2.png) no-repeat center; */
	content: "";
}
.owl-dots {
	text-align: right;
}
.owl-dots div {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 2px;
	background: #F17024;
	opacity: 0.3;
}
.owl-dots div.active {
	opacity: 1;
}
.post_date {
	margin-bottom: 10px;
}
.post_date a {
	text-transform: capitalize;
	color: #F17024;
}
/* team */

.owl-carousel.team-carousel .owl-item .icon img {
	height: auto;
	width: auto;
	margin: auto;
}
/*======================
	17. shop-left
=============================*/

.sidebar_widgets .sidebar_nav ul li.btn-sidebar {
	background: #f7f7f7;
	text-align: center;
	margin: 20px 0 0;
	border: 1px solid #ccc;
}
.sidebar_widgets .sidebar_nav ul li.btn-sidebar a {
	padding: 5px 30px 5px 30px;
}
.sidebar_widgets .sidebar_nav ul li.btn-sidebar a:hover {
	text-decoration: none;
	border: 0px;
}
.sidebar_nav ul li.btn-sidebar a:after {
	display: none;
}
#tl .irs--round .irs-handle {
	top: 26px;
	width: 24px;
	height: 24px;
	border: 4px solid #F17024;
	background-color: white;
	border-radius: 24px;
	box-shadow: 0 1px 3px rgba(241, 111, 35, 0.3);
}
#tl .irs--round .irs-bar {
	top: 36px;
	height: 4px;
	background-color: #F17024;
}
#tl .irs--round .irs-from,
#tl .irs--round .irs-to,
#tl .irs--round .irs-single {
	font-size: 14px;
	line-height: 1;
	text-shadow: none;
	padding: 3px 5px;
	background-color: #F17024;
	color: white;
	border-radius: 4px;
}
.img-shop {
	background: #f2f2f2;
	display: block;
	margin-bottom: 20px;
}
.tl-shop-single {
	border: 1px solid #eae8e8;
	border-radius: 0;
	background: #fff;
	padding: 0 0 36px 0;
	position: relative;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
}
.img-shop-wrapper {
	border-radius: 5px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	margin-bottom: 30px;
	border-bottom: 4px solid #010101;
	padding-bottom: 10px;
}
.tl-shop-single,
.tl-img-shop {
	background: #f2f2f2;
	display: block;
	margin-bottom: 20px;
}
.tl-shop-single,
.tl-product-content {
	margin-bottom: 33px;
}
.tl-shop-single,
.tl-product-content h4 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	color: #010101;
	margin-bottom: 12px;
}
.tl-shop-single,
.tl-product-content p {
	color: #010101;
	font-weight: 500;
	font-size: 16px;
}
.tl-product-content p .off-price {
	text-decoration: line-through;
	color: #777777;
	font-weight: 400;
}
.img-shop-wrapper,
.tl-shop-single a {
	text-transform: none;
}
.tl-shop-single,
.btn-sm {
	letter-spacing: .5px;
}
.btn .btn-sm {
	padding: 13px 35px;
	border: 1px solid #ff6a48;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 2px;
	transition: .3s;
	font-size: 14px;
}
.shop-view p {
	text-align: right;
}
.tl-shop-single{
	max-width: 100%;
	height: auto;
	width: auto;
	padding: 35px 0px;
	margin-bottom: 30px;
}
.tl-product-content {
	margin: 25px 0;
	padding: 0 10px;
	text-align: center;
}
.tl-product-content p {
	font-size: 15px;
	line-height: 24px;
	padding: 0px;
	color: #F17024;
	font-weight: 600;
}
/*======================
	17. pagination
=============================*/

a:hover {
	text-decoration: none;
}
.page-link:hover {
	background-color: #e0747e;
	color: #ffffff;
}
.page-link {
	color: #212529;
}
.page-navigation .page-item.active .page-link {
	z-index: 1;
	color: #ffffff;
	background-color: #010101;
	border-color: transparent;
}
.pagination {
	text-align: center;
	display: block;
}
.shop-top-filter {
	margin-bottom: 25px;
}
.shop-top-filter select {
	padding: 10px;
	width: 100%;
	border: 1px solid #ddd;
}
.shop-top-filter .shop-view p {
	margin-bottom: 0px;
	text-align: right;
}
/*======================
	17. log-in
=============================*/

.tl-header {
	z-index: 9;
}
.tl-header {
	background-color: #010101;
}
.tl-wrpr-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.tl-logo {
	padding: 0;
	max-width: 185px;
}
.img {
	max-width: 100%;
	width: auto;
	height: auto;
}
.login-page h4 {
	color: #000;
	margin: 0 0 20px;
	font-weight: 600;
}
.tl,
.login-page p {
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 15px;
	font-family: 'Noto Sans', sans-serif;
}
.tl a {
	text-decoration: none;
}
.login-page a {
	color: #010101;
}
.login-page a:hover {
	color: #212529;
}
#tl .custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	background-color: #010101;
}

/*======================
	Our-Monks
=============================*/

.sa-paster-about-area {
	margin-top: 15px;
}

.sa-paster-about-area h3 {
	font-size: 36px;
	margin-bottom: 22px;
}

.sa-paster-about-area .designation {
	font-size: 18px;
	position: relative;
	color: #ff6a48;;
}
.ss-pastor-img-wrap{
	position: relative;
}
.ss-pastor-img-wrap::before{
	content: '';
	position: absolute;
	top: 10px;
	right: -10px;
	width: 100%;
	height: 100%;
	background-color: #f17024;
	z-index: -1;
	transition: 0.3s;
}
.ss-pastor-img-wrap:hover::before{
	top: 0;
	right: 0;
}

.sa-paster-about-area .designation:after {
	content: '';
	position: absolute;
	height: 3px;
	width: 15px;
	left: 0;
	background: #ff6a48;;
	bottom: -8px;
}
.ss-about-pastor.style-2 .designation{
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 2px;
}
.ss-about-pastor.style-2 .designation::after{
	display: none;
}

.ss-about-pastor.style-2 img{
	border-radius: 50%;
}
.ss-about-pastor.style-2 .sa-paster-about-area h2{
	margin-bottom: 22px;
}
.ss-about-pastor.style-2 .ss-pastor-list-title{
	background-color: transparent;
}

.ss-about-pastor.style-3 .sa-paster-about-area{
	display: flex;
	align-items: center;
	height: 100%;
}
.ss-pastors-list .nav-tabs>li{
	width: 100%;
	margin: 0;
}
.ss-pastor-list-title{
	background-color: #ff6a48;
	margin: 0;
	padding: 10px 40px;
	font-weight: 700;
	font-size: 16px;
	color: #fff;
}
.ss-pastors-list .nav-tabs>li a{
	display: block;
	background: #363636;
	padding: 20px 40px;
	margin: 0;
}
.ss-pastors-list .nav-tabs h5,
.ss-pastors-list .nav-tabs p{
	margin: 0;
	color: #fff;
}
.ss-pastors-list .nav-tabs p{
	font-style: italic;
}
.ss-pastors-list .nav-tabs .media-body{
	text-align: left;
	margin-left: 20px;
}
.ss-pastors-list .nav-tabs>li:nth-child(odd) a{
	background: #2f2f2f;
}
.ss-pastors-list .nav-tabs>li a:hover{
	opacity: 0.9;
}

.sa-team-social ul {
	margin: 0 auto;
	display: inline-block;
	padding-left: 0;
}

.sa-team-social li {
	list-style: none;
	float: left;
	margin: 0 15px 10px 0;
}

.sa-team-social li:last-child {
	margin-right: 0;
}

.sa-team-social li a i {
	height: 35px;
	width: 35px;
	line-height: 33px;
	border: 1px solid #e7eaee;
	border-radius: 50px;
	transition: 0.3s;
	color: #8090a3;
}

.sa-team-social li i:hover {
	color: #fff;
	background: #ff6a48;
	border: 1px solid #ff6a48;
}

/* fixes */
.commentlist .comment:last-child,
.post_detail ul.commentlist,
.commentlist ol .comment.the-comment{
	margin-bottom: 0;
}
