/* You can add global styles to this file, and also import other style files */
@import '~magnific-popup/dist/magnific-popup';
button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.mobile-open.mobile-menu {
    display: block;
}
.custom-accordion .card{
    border: none;
    margin-bottom: 0;
}
.custom-accordion .card-header:first-child {
    border-top: #e8e7e7 solid 1px;
    border-radius: 0;
    padding: 0;
}
.custom-accordion .card-header {
    border-bottom: #e8e7e7 solid 0px;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
}
.custom-accordion .card:last-child .card-header{
    border-bottom: #e8e7e7 solid 1px;
}
.custom-accordion .card-header .btn {
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    position: relative;
    font-size: 18px;
    font-family: 'Marcellus', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    color: #010101;
    line-height: 1.2;
    padding: 28px 50px 28px 0;
    display: block;
    letter-spacing: normal;
}
.custom-accordion .card-header .btn.collapsed::before {
    position: absolute;
    top: 26px;
    right: 7px;
    background: #b7b7b7;
    height: 16px;
    width: 2px;
    content: "";
}
.custom-accordion .card-header .btn::after {
    position: absolute;
    top: 33px;
    right: 0;
    background: #b7b7b7;
    height: 2px;
    width: 16px;
    content: "";
}
.custom-accordion .card-body{
    padding: 0;
}
.custom-accordion .card-body .sermons_inside {
    text-align: left;
    padding: 15px 0 50px;
    padding-right: 0;
    float: none;
    width: 100%;
}
.custom-accordion .card-body .sermons_inside li {
    margin: 0 15px 0 0;
}

/* Counter */
countdown .countdown {
    display: flex;
	width: 100%;
    flex-wrap: wrap;
	justify-content: space-between!important;
}

countdown .countdown .measurements {
   	padding: 0;
    border: none;
    margin-right: 15px;
    color: #fff;
    margin-bottom: 0;
    text-align: center;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f17024a6;
	flex: unset!important;
}
countdown .countdown .measurements .measurements-number{
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
}
countdown .countdown .measurements .measurements-text{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    margin: 10px 0 0 0;
    text-transform: uppercase;
}
.sermons_img img{
    height: 160px;
    object-fit: cover;
}
.post_thumb a{
    display: block;
    height: 100%;
    width: 100%;
}
.post_thumb a img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product_action ul li.active a{
    color: #ffffff;
    border-color: #F17024;
    background: #F17024;
}
.tl-product-review ul,
.tl-product-review .nav-pills {
    border-bottom: 1px solid #ddd;
    padding: 12px 0px 8px 0;
    list-style: none;
    margin-bottom: 20px;
}
.tl-product-review .nav-pills .nav-item {
    margin: 0;
}
.tl-product-review .nav-pills .nav-item .nav-link {
    text-decoration: none;
    position: relative;
    padding: 21px 30px;
    font-size: 16px;
    line-height: 36px;
    font-weight: 400;
    color: #8090a3;
    transition: 0.4s;
    display: inline;
    border-radius: 0;
}
.tl-product-review .nav-pills .nav-item .nav-link:before {
    content: " ";
    width: 1px;
    height: 30px;
    background: #ddd;
    position: absolute;
    right: 0;
    bottom: 25%;
}
.tl-product-review .nav-pills .nav-item .nav-link.active:after {
    content: " ";
    left: 0;
    bottom: 2px;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #25283a;
}
.tl-product-review .nav-pills .nav-item:last-child .nav-link:before {
    display: none;
}
.tl-product-review .nav-pills .nav-item .nav-link.active {
    color: #022147;
    background: transparent;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin-top: 0;
	justify-content: flex-end;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next, pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span, pagination-template .ngx-pagination li.pagination-previous span, pagination-template .ngx-pagination li a {
	border-radius: 0px;
	color: #25283a;
	border: 1px solid transparent;
	background-color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 13px!important;
	margin: 0;
	position: relative;
	margin-left: 5px;
	display: block;
	line-height: 1.25;
}
pagination-template .ngx-pagination li.current {
	border-radius: 0px;
	color: #25283a;
	border: 1px solid #25283a;
	background-color: #fff; 
	font-size: 14px;
	font-weight: 600;
	padding: 10px 13px!important;
	margin: 0;
	position: relative;
	margin-left: 5px;
	display: block;
	line-height: 1.25;
}
pagination-template .ngx-pagination li.pagination-next::after, pagination-template .ngx-pagination li.pagination-next a:after, pagination-template .ngx-pagination li.pagination-previous a::before, pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
pagination-template .ngx-pagination li.pagination-previous a,
pagination-template .ngx-pagination li.pagination-previous span{
    margin: 0;
}
@media (max-width: 600px){
    pagination-template .ngx-pagination{
        justify-content: center;
        margin-top: 20px;
    }
    .ngx-pagination.responsive .small-screen{
        display: none!important;
    }
    .ngx-pagination.responsive li:not(.small-screen):not(.pagination-previous):not(.pagination-next) {
        display: block!important;
    }
}
/* Range slider */
.ngx-slider .ngx-slider-bubble{
    padding: 1px 6px!important;
    color: #ffffff!important;
    font-size: 14px!important;
    background-color: #F17024;
    border-radius: 5px;
}
.ngx-slider .ngx-slider-bubble::after{
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #F17024;
}
.ngx-slider .ngx-slider-bar{
    background: #e1e4e9!important;
    height: 4px!important;
}
.ngx-slider .ngx-slider-selection{
    background: #F17024!important;
}
.ngx-slider .ngx-slider-pointer:after {
    display: none!important;
}
.ngx-slider .ngx-slider-pointer{
    background-color: #ffffff!important;
    width: 24px!important;
    height: 24px!important;
    top: -10px!important;
    border: 4px solid #F17024;
}

.tl-single-product-rating{
    margin-bottom: 20px;
}
.tl-single-product-rating span:last-child {
    font-size: initial;
    margin-left: 1px;
}
.tl-single-product-rating a {
    margin-left: 10px;
}
.tl-single-product-rating span i {
    color: #ffc107;
}
.tl-single-product-details h2{
    float: none;
    max-width: 100%;
}
.tl-product-review .tab-content h2 {
    font-size: 24px;
    line-height: 36px;
}
.tl-product-review .bd {
    border-bottom: none;
}
.tl-single-product-details-area {
    position: relative;
}
.tl-single-product-details-social {
    position: absolute;
    top: 0%;
    right: 1%;
}
.tl-single-product-details-social ul {
    padding: 0;
    margin: 0;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
}
.tl-single-product-details-social ul li {
    list-style: none;
    margin: 0;
}
.tl-single-product-details-social ul li:last-child {
    border: 0;
}
.tl-single-product-details-social ul li a {
    text-decoration: none;
    padding: 10px 15px;
    font-size: 14px;
    display: block;
    overflow: hidden;
    color: #e6dcdc;
}
.tl-single-product-purches .quantity {
    display: inline-block;
}
.tl-single-product-purches .quantity button {
    border-radius: 3px;
    background-color: rgb(245, 245, 245);
    border: none;
    padding: 7px 20px;
    margin: 0px 7px;
    cursor: pointer;
}
.tl-single-product-purches .quantity input {
    width: 60px;
    text-align: center;
    padding: 3px 5px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #ddd;
    border-radius: 3px;
}
.tl-single-product-purches h4 {
    display: inline-block;
    min-width: 188px;
    margin: 0 0 20px 0;
    font-weight: 600;
    color: #343a40;
    font-size: 36px;
    line-height: 42px;
}
.tl-single-product-purches .quantity button {
    border-radius: 3px;
    background-color: rgb(245, 245, 245);
    border: none;
    padding: 7px 20px;
    margin: 0px 7px;
    cursor: pointer;
}
.box_wrap .icon img{
    width: auto;
    display: inline;
}
.layout-switcher .nav-pills{
    justify-content: flex-end;
}
.layout-switcher .nav-pills .nav-item .nav-link{
    padding: 0;
    border: 0;
    border-radius: 0;
}
.layout-switcher .nav-pills .nav-item .nav-link.active{
    color: #f17024;
    background-color: transparent;
}
.tl-shop-title-area {
    margin: 60px 0 36px 0;
}
.product-thumbnail {
    width: 32px;
    display: inline-block;
    margin-right: 1rem;
}
.table .hm-align {
    vertical-align: middle;
}
.hm-qty-btn {
    padding: .375rem .75rem;
    font-family: sans-serif;
}
.qty {
    width: 40px;
    text-align: center;
    border: 0;
    outline: none;
}
.checkout-form .card-title {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}
.checkout-form .card-title h6 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 20px;
}
.checkout-form .card-title h6 a{
    color: #201c15;
}
.checkout-form .card {
    margin-bottom: 30px;
}
.hm-pay-form tbody td {
    border: none;
    padding: 0.5rem;
}
.checkout-form .form-control {
    min-height: 50px;
}