@charset "utf-8";

/*
Theme Name: templezen - Buddha Temple HTML5 Template.
Author: Themeganj
Author URL: https://www.templatemonster.com/vendors/themeganjthemes/
Version:1.0
*/
/*====================
Min-Max-width
==========================*/
@media (min-width: 991px) and (max-width: 1200px) {
	.latest_event_sermons .box_wrap {
		padding: 20px;
	}
	.player-controls.scrubber {
		width: 65%;
	}
	.latest_event_sermons .event_list .event_info {
		padding: 15px 15px 15px 126px;
	}
	.latest_event_sermons .event_list.event-list-alignemt .event_info {
		padding: 15px 126px 15px 15px;
	}
	.email_input {
		width: 66%;
	}
	.footer_links a {
		margin: 0 0 0 12px;
	}
	.box_heading {
		margin: 0 0 50px;
	}
	.form-label {
		font-size: 14px;
	}
	.event_venue ul li span {
		float: none;
		width: 100%;
	}
	.event_date {
		padding: 22px 5px;
	}
	.event_info h4 {
		margin: 0px auto 14px;
	}
	.sermons_info_wrap {
		padding: 12px 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-nav li a {
		font-size: 13px;
		padding: 13px 4px;
	}
	.navbar-nav .btn {
		padding: 10px 10px;
	}
	.header_top p.address {
		display: none;
	}
	.intro_text {
		padding: 100px 0;
		margin: 0 auto;
	}
	.intro_text h1 {
		font-size: 55px;
	}
	.latest_event_sermons .box_wrap {
		padding: 22px;
	}
	.next_sermons .sermons_inside {
		padding: 17px 0;
	}
	.audio-player #play-btn {
		margin-right: 5px;
	}
	.player-controls.scrubber {
		width: 57%;
		margin-right: 5px;
	}
	.audio-player .player-controls progress {
		width: 55%;
	}
	.audio-player {
		padding: 0;
	}
	.event_info ul li {
		font-size: 13px;
		line-height: 23px;
	}
	.about_us {
		max-width: 530px;
	}
	.latest_event_sermons .event_list .event_date {
		width: 82px;
	}
	.latest_event_sermons .event_list.event-list-alignemt .event_date {
    top: 30%;
    left: 70%;
	}
	.latest_event_sermons .event_list .event_info {
		padding: 25px 25px 25px 100px;
	}
	.blog_info {
		/* margin: 0 15px; */
		padding: 20px 15px;
	}
	.follow_us ul li {
		margin: 0px 0 0px 18px;
	}
	.newsletter {
		padding: 0;
	}
	.email_input {
		width: 61%;
	}
	.widget_inner {
		padding: 0;
	}
	.widget_inner p,
	.widget_inner a,
	.footer_links a,
	.footer_bottom p {
		font-size: 14px;
		line-height: 20px;
	}
	.section-padding {
		padding: 100px 0;
	}
	.insta_url {
		font-size: 20px !important;
		line-height: 48px !important;
	}
	.btn {
		font-size: 13px;
		padding: 8px 26px;
	}
	.inner_wp {
		padding: 180px 0 0;
	}
	.features_wrap {
		padding: 0 0 0 55px;
	}
	.f__icon {
		width: 40px;
		height: 40px;
		font-size: 16px;
		line-height: 32px;
	}
	.features_wrap h6 {
		font-size: 16px;
	}
	.features_wrap p {
		font-size: 14px;
		line-height: 24px;
	}
	.video-wrap-section .video_wrap .video_icon {
		padding: 7% 0;
	}
	.home-video .video-icon{
		padding: 34% 0;
	}
	.margin_60 {
		margin: 0px auto 40px;
	}
	.box_wrap {
		margin: 0 auto 35px;
	}
	.footer_nav li {
		margin: 0 auto;
	}
	.sidebar_widgets {
		padding: 20px 15px;
	}
	.widget_title h6::after {
		left: -15px;
	}
	.sidebar_nav ul li a {
		padding: 5px 20px 5px 0;
	}
	.sidebar_nav ul li {
		margin: 0 auto;
	}
	.widget_title {
		padding: 0;
	}
	.post_thumb {
		height: 50px;
		width: 50px;
	}
	.recent_post li {
		padding-left: 62px;
	}
	.recent_post li:last-child {
		margin: 0 auto;
	}
	.recent_post h6 {
		font-size: 14px;
		margin: 0px;
	}
	.tag_cloud a {
		padding: 2px 15px;
		font-size: 13px;
		margin: 0 2px 5px 0;
	}
	.psot_tags,
	.psot_share {
		padding: 12px 0;
		line-height: 20px;
	}
	.comment-respond,
	.all_comments {
		padding: 25px;
	}
	.map_wrap {
		height: 300px;
	}
	.select_amount li {
		padding: 8px 15px;
		margin-right: 8px;
	}
	.event_venue ul li span {
		float: none;
		width: 100%;
	}
	.sidebar_widgets .event_venue ul {
		padding: 20px 24px;
	}
	.date {
		width: 20%;
	}
	.timer {
		width: calc(100% - 21%);
	}
	.timer.event-timer {
    margin: 0 auto;
    text-align: center;
    width: calc(100% - 20%);
	}
	.event_date {
		padding: 15px 5px;
	}
	.event_info h4 {
		margin: 0px auto 14px;
		font-size: 22px;
	}
	.sermons_wrap {
		padding: 20px;
	}
	.sermons_img {
		float: left;
		width: 36%;
	}
	.sermons_info_wrap {
		float: right;
		width: 60%;
		padding: 12px 0;
	}
	.sermons_info,
	.sermons_inside {
		float: none;
		width: 100%;
	}
	.sermons_inside {
		text-align: left;
		padding: 18px 0 0 0;
	}
	.sermons_info h5 {
		margin: 0 auto 10px;
	}
	.sermons_inside ul li {
		font-size: 17px;
		margin: 0 10px 0 0;
	}
	.sermons_inside ul li a {
		height: 50px;
		width: 50px;
		line-height: 43px;
	}
	h6 {
		font-size: 17px;
	}
	.sidebar_title,
	.sermons_list {
		padding: 20px;
	}
	.all_comments {
		margin: 0 auto 50px;
	}
	/* index testimonal css */
	.about_company .nav-tabs.testi-nav-tabs li a {
		padding: 9px 15px;
	}
	/* donation section */
	.donation-img-section .select_amount li {
		padding: 3px 6px;
		margin-right: 0px;
		font-size: 13px;
	}
}
/*====================
Min-width
==========================*/
@media (min-width: 767px) {
	.mobile-menu {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
}
@media (min-width: 768px) {
	.collapse.navbar-collapse {
		display: block;
	}
}
/*====================
Max-width
==========================*/
@media (max-width: 1200px) {
	.header_top .top-btn li:nth-child(1),
	.header_top .top-btn li:nth-child(2) {
		display: none;
	}
	.trip-place-wrapper-content .trip-info {
		padding: 20px 10px;
	}
}
@media (max-width: 991px) {
	.shop-top-filter .shop-view p{
		text-align: left;
	}
	.intro_text {
		text-align: center;
	}
	.shop-view{
		margin-top: 30px;
	}
	.intro_text p {
		text-align: center;
		margin: 0 auto 30px
	}
	.Mb_20 {
		margin-bottom: 20px;
	}

	.about-us-content p:last-child{
		margin-bottom: 25px;
	}

	.header_top .top-btn li:nth-child(1),
	.header_top .top-btn li:nth-child(2) {
		display: none;
	}
	h3 {
		font-size: 30px;
	}
	.ratings{
		display: block;
	}
	.sermon-box .sermons_meta {
    display: block;
	}
	.footer_bottom {
		padding: 15px 0;
		text-align: center;
	}
	.footer_links{
		text-align: center;
	}
	.footer_links a {
		margin: 0 6px;
	}
	#back-top {
		display: none !important;
	}
	.sa-paster-about-area {
		margin-bottom: 30px;
	}
}
@media (max-width: 992px) {
	.trip-place-wrapper-content .trip-info {
		padding: 20px;
	}
}
@media (max-width: 767px) {
	.ratings{
		display: flex;
	}
	.sort-select-filter{
		margin-bottom: 30px;
	}
	.custom-text{
		text-align: center;
	}
	.text-custom-align-right{
		text-align: center!important;
	}
	.donation_form .input-group{
		margin: 0 auto 25px;
	}
	.trip-place-wrapper-content {
		display: block;
	}
	.trip-place-wrapper-content .trip-info,
	.trip-place-wrapper-content .trip-place-img {
		width: 100%;
		max-width: 100%;
	}
	.trip-place-wrapper-content .trip-info {
		border-top: 0;
		border-left: 2px solid #efefef;
	}
	.trip-place-wrapper-content .trip-place-img {
		height: 250px;
	}
	/*  Mobile Child Toggle Buttons */
	#nav-toggle,
	#nav-toggle-label {
		display: block;
	}
	.mobile-open .mobile-menu {
		display: block;
	}
	.child-trigger {
		display: block;
		cursor: pointer;
		position: absolute;
		top: 0px;
		right: 0px;
		width: 55px;
		min-width: 55px;
		height: 60px;
		padding: 0;
		border-left: 1px dotted rgba(0, 0, 0, 0.25);
	}
	.child-trigger:hover {
		text-decoration: none;
	}
	.child-trigger i {
		position: relative;
		top: 50%;
		margin: 0 auto;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	.child-trigger i:after {
		position: absolute;
		content: '';
	}
	.child-trigger i,
	.child-trigger i:after {
		width: 10px;
		height: 2px;
		background-color: #000;
		display: block;
	}
	.child-trigger i:after {
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
	.child-trigger.child-open i {} .child-trigger.child-open i:after {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg);
	}
	.mobile-menu .wd-menu .sub-menu .child-trigger {
		height: 50px;
	}
	#nav-toggle {
		position: fixed;
		top: 0;
		right: 0;
		-webkit-opacity: 0;
		-ms-opacity: 0;
		opacity: 0;
	}
	/*  Mobile Hamburger */
	#nav-toggle-label * {
		transition: 0.25s ease-in-out;
	}
	#nav-toggle-label {
		height: 28px;
		width: 28px;
		position: relative;
		z-index: 9999;
		right: 0;
		top: 0;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		cursor: pointer;
		transition: 0.25s ease-in-out;
		margin: 0;
	}
	.mobile-open #nav-toggle-label,
	.mobile-open #nav-toggle-label.slide-out {
		background: transparent;
		right: 0;
	}
	.mobile-open #nav-toggle-label {
		right: 30px;
		position: absolute;
	}
	#nav-toggle-label #hamburger {
		position: relative;
		height: auto;
		width: 100%;
		top: 6px;
		right: 0;
	}
	#nav-toggle-label #hamburger span {
		display: block;
		width: 100%;
		height: 2px;
		position: relative;
		margin: 0 0 8px 0;
		background: #fff;
	}
	#nav-toggle-label #hamburger span:nth-child(1) {
		transition-delay: 0.5s;
	}
	#nav-toggle-label #hamburger span:nth-child(2) {
		;
		transition-delay: 0.625s;
	}
	#nav-toggle-label #hamburger span:nth-child(3) {
		transition-delay: 0.75s;
		margin: 0;
	}
	.mobile-open #nav-toggle-label #hamburger span,
	.mobile-open #nav-toggle-label.slide-out #hamburger span {
		width: 0%;
	}
	.mobile-open #nav-toggle-label #hamburger span:nth-child(1),
	.mobile-open #nav-toggle-label.slide-out #hamburger span:nth-child(1) {
		transition-delay: 0s;
	}
	.mobile-open #nav-toggle-label #hamburger span:nth-child(2),
	.mobile-open #nav-toggle-label.slide-out #hamburger span:nth-child(2) {
		transition-delay: 0.125s;
	}
	.mobile-open #nav-toggle-label #hamburger span:nth-child(3),
	.mobile-open #nav-toggle-label.slide-out #hamburger span:nth-child(3) {
		transition-delay: 0.25s;
	}
	#nav-toggle-label #cross {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	#nav-toggle-label #cross span {
		display: block;
		background: #000;
	}
	#nav-toggle-label #cross span:nth-child(1) {
		height: 0%;
		width: 2px;
		position: absolute;
		top: 0;
		right: 50%;
		margin-left: -1px;
		transition-delay: 0s;
	}
	#nav-toggle-label #cross span:nth-child(2) {
		width: 0%;
		height: 2px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -1px;
		;
		transition-delay: 0.25s;
	}
	.mobile-open #nav-toggle-label #cross span:nth-child(1),
	.mobile-open #nav-toggle-label.slide-out #cross span:nth-child(1) {
		height: 100%;
		transition-delay: 0.625s;
	}
	.mobile-open #nav-toggle-label #cross span:nth-child(2),
	.mobile-open #nav-toggle-label.slide-out #cross span:nth-child(2) {
		width: 100%;
		transition-delay: 0.375s;
	}
	.mobile-menu {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100vh;
		background: #F17024;
		padding: 50px 0 0;
		z-index: 99;
		overflow: hidden;
		display: none;
	}
	body.mobile-open {
		overflow: hidden;
	}
	.mobile-menu .wd-menu {
		display: block;
		padding: 0;
		margin: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
		padding: 50px 0;
	}
	li.has-child .sub-menu {
		display: none;
	}
	.mobile-menu .wd-menu li a {
		display: block;
		padding: 0 30px;
		line-height: 50px;
		font-weight: 600;
		color: #fff;
	}
	.mobile-menu .wd-menu>li>a {
		line-height: 60px;
		font-weight: 700;
	}
	.mobile-menu .wd-menu>li>a:after {
		display: none;
	}
	.mobile-menu .wd-menu>li {
		padding: 0;
		margin: 0;
	}
	.mobile-menu .wd-menu .sub-menu li {
		margin: 0;
	}
	.mobile-menu .wd-menu .sub-menu .sub-menu>li>a {
		font-weight: 500;
	}
	.wd-menu>li.has-child>a:after,
	li.has-child>a:after,
	.shop .widget-title[data-toggle="collapse"]:after {
		display: none;
	}
	.mobile-menu .wd-menu li a {
		display: block;
		padding: 0 30px;
		line-height: 50px;
		font-weight: 600;
		color: #000;
	}
	.wd-menu li.has-child {
		position: relative;
	}
	.video-bg {
		display: none;
	}
	.btn {
		font-size: 13px;
		padding: 8px 22px;
	}
	.header_top p.address {
		display: none;
	}
	.follow_us ul li {
		margin: 0px 0 0px 15px;
	}
	.header_top {
		padding: 4px 0;
	}
	#menu_slide {
		display: block;
		float: right;
		background: none;
		border: 0;
		width: 32px;
		margin: 5px auto;
		cursor: pointer;
		padding: 0;
	}
	.icon-bar {
		display: block;
		width: 100%;
		height: 2px;
		background: #fff;
		margin: 0 auto 6px;
	}
	.navbar-header {
		display: block;
		width: 100%;
	}
	.logo {
		float: left;
	}
	.navbar-collapse {
		width: 100% !important;
		text-align: left !important;
		background: #fff;
	}
	.navbar-nav ul {
		background: #fff;
	}
	.navbar-nav li {
		display: block;
	}
	.navbar-nav li a {
		color: #333;
		padding: 6px 15px;
		border-bottom: rgba(0, 0, 0, 0.15) solid 1px;
	}
	.navbar-nav .btn {
		color: #fff;
	}
	ul.sub-menu {
		position: relative;
		width: auto;
	}
	.navbar ul li ul.sub-menu li a {
		padding: 4px 17px;
		font-size: 13px;
	}
	.navbar ul li ul.sub-menu li a {
		font-size: 13px;
	}
	.tl-video-header .video_intro_text {
		padding: 200px 0;
	}
	.intro_text {
		margin: 0 auto;
		padding: 90px 0 60px;
	}
	.intro_text h1 {
		font-size: 38px;
	}
	.intro_text p {
		font-size: 15px;
		margin: 0 auto 30px;
		max-width: 80%;
		line-height: 24px;
	}
	#intro .owl-nav div {
		width: 36px;
		height: 36px;
	}
	#intro .owl-nav div::after {
		line-height: 35px;
		font-size: 22px;
	}
	.btn {
		font-size: 13px;
		line-height: 25px;
		padding: 10px 22px;
	}
	.latest_event_sermons .box_wrap {
		padding: 25px 20px;
		margin: 0 auto 30px;
		text-align: center;
	}
	.home-sermon .next_sermons .sermons_inside,
	.home-sermon .sermons_meta{
		text-align: center;
	}
	.home-sermon .sermons_meta li {
    float: none;
	}
	.audio-player #play-btn {
		width: 17%;
		margin: 0 3% 0 0;
	}
	.player-controls.scrubber {
		width: 59%;
		margin-right: 3%;
		padding: 13px 10px;
		text-align: center;
	}
	.next_prev {
		width: 17%;
		margin: 0 1% 0 0;
	}
	.audio-player .player-controls progress {
		width: 57%;
	}
	.section-padding {
		padding: 70px 0;
	}
	.section-header h2 {
		margin: 0 auto 20px;
		font-size: 28px;
	}
	.about_intro {
		background: none;
	}
	.causes_chart p {
		display: block;
		margin: 10px auto;
	}
	#causes .owl-nav{
		display: none;
	}
	h2 {
		font-size: 28px;
	}
	.event_list {
		margin: 0 auto 50px;
	}
	.heading {
		margin: 0 auto 20px;
	}
	.panel-body .sermons_inside li {
		margin: 0 5px 0 0;
	}
	.panel-title a {
		padding: 18px 50px 18px 0;
	}
	.margin_60 {
		margin: 0px auto 60px;
	}
	.video_icon a {
		width: 50px;
		height: 50px;
		line-height: 48px;
		font-size: 20px;
	}
	.our_testimonials .video_icon {
		padding: 20% 0px 20% 8%;
	}
	#testimonials {
		padding: 200px 20px 30px 20px;
	}
	#testimonials p {
		font-size: 17px;
		line-height: 26px;
		margin: 0 auto 22px;
	}
	.blog_info {
		padding: 15px;
	}
	p {
		line-height: 24px;
	}
	.blog_wrap {
		margin: 0 auto 40px;
	}
	.top_widget {
		padding-top: 10px;
		border-right: 0;
		padding-bottom: 10px;
		border-bottom: rgba(255, 255, 255, 0.15) solid 1px;
	}
	.footer_logo,
	footer .follow_us ul {
		text-align: center;
	}
	.footer_widget {
		margin: 40px auto 0;
		padding-bottom: 30px;
		border-bottom: #212121 solid 1px;
		border-right: 0;
	}
	.footer_widget h5 {
		margin: 0 auto 5px;
	}
	.widget_inner {
		padding: 0;
	}

	.about_company .testi-nav-tabs{
		justify-content: center;
	}
	.about_company,
	.donation-img-section p,
	.donation-img-section .section-header,
	.donation_form {
		text-align: center;
	}
	#inner_intro.section-padding {
		padding: 60px 0;
		text-align: center;
	}
	.inner_wp {
		padding: 130px 0 0;
	}
	.breadcrumb {
		text-align: center;
	}
	.img_wrap {
		margin-top: 40px;
	}
	.box_wrap {
		margin: 0 auto 30px;
	}
	.post_detail .post_img {
		margin: 0 auto 20px;
	}
	blockquote p {
		font-size: 16px;
	}
	.psot_tags,
	.psot_share {
		padding: 0;
		border: 0;
	}
	.tags_share {
		padding: 12px 0;
		margin: 40px auto;
	}
	.comment-respond,
	.all_comments {
		padding: 20px;
	}
	.all_comments {
		margin: 0 auto 40px;
	}
	.comment-avatar {
		width: 30px;
	}
	.comment-content {
		width: calc(100% - 50px);
		padding-bottom: 24px;
	}
	.commentlist .comment ol {
		padding-left: 50px;
	}
	.sidebar_wrap {
		margin-top: 40px;
	}
	.tag_cloud a {
		padding: 3px 14px;
		font-size: 13px;
		margin: 0 5px 5px 0;
	}
	.map_wrap {
		height: 350px;
		margin: 0 auto 70px;
	}
	.form_wrap {
		padding: 25px;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 10px;
		border: #f2f2f2 solid 1px;
		margin-top: 40px;
	}
	.contact_wrap {
		border: 0;
		box-shadow: none;
	}
	.contact_info {
		padding: 40px 15px;
	}
	.box_heading {
		margin: 0 0 50px;
	}
	.m_none {
		display: none;
	}
	.select_amount li {
		padding: 8px 18px;
		margin-right: 7px;
	}
	.date {
		width: 20%;
	}
	.timer {
		width: calc(100% - 21%);
	}
	.timer.event-timer {
    width: calc(100% - 10%);
	}
	.commentlist {
		margin: 0 auto !important;
	}
	.events_wrap {
		margin: 0 auto 60px;
	}
	.events_wrap .event_img {
		float: none;
		width: 100%;
		margin: 0 auto;
	}
	.events_wrap .event_info {
		float: none;
		width: 100%;
		padding-top: 10px;
	}
	.events_wrap .event_info ul li {
		font-size: 15px;
	}
	.events_wrap .event_date {
		float: none;
		width: 100%;
		text-align: left;
		padding: 1px 13px;
		font-size: 15px;
		display: inline-block;
		margin: 0 auto;
		width: auto;
	}
	.events_wrap .event_date span {
		display: inline;
		font-size: 15px;
		margin: 0 auto;
	}
	.video_icon,
	.video-wrap-section .video_wrap .video_icon {
		padding: 7.5% 0;
	}
	.sermons_wrap {
		padding: 20px;
	}
	.sermons_img {
		float: left;
		width: 36%;
	}
	.sermons_info_wrap {
		float: right;
		width: 60%;
		padding: 0;
	}
	.sermons_info,
	.sermons_inside {
		float: none;
		width: 100%;
	}
	.sermons_inside {
		text-align: left;
		padding: 18px 0 0 0;
	}
	.sermons_info h5 {
		margin: 0 auto 10px;
	}
	.sermons_inside ul li {
		font-size: 17px;
		margin: 0 10px 0 0;
	}
	.sermons_inside ul li a {
		height: 40px;
		width: 40px;
		line-height: 33px;
		border-width: 1px;
	}
	.nav-border {
		border: 0px;
	}
	#header .logo-big {
		position: inherit;
		left: 0;
		background: rgba(255, 255, 255, 0);
		color: #ffffff;
		height: auto;
		width: auto;
		display: none !important;
	}
	#header .logo-mobil {
		display: block;
	}
	.about-us-image{
		margin-bottom: 30px;
	}
	.about-us-content.section-header{
		padding-bottom: 0;
	}
}
@media (max-width: 600px) {
	.pagination_wrap {
		text-align: center;
	}
	.pagination_wrap .pagination {
		text-align: center;
		padding: 15px 0 0;
	}
	.select_amount li {
		padding: 3px 6px;
		margin-right: 0px;
		font-size: 13px;
	}
	.donation-img-section .select_amount li {
		padding: 3px 6px;
		margin-right: 0px;
		font-size: 13px;
	}
	.donation_form .form-control {
		margin-bottom: 3px;
	}
	.event_venue ul li span {
		float: none;
		width: 100%;
	}
	.date {
		width: 24%;
		padding: 13px 0;
	}
	.countdown-period {
		padding: 13px 0;
		text-align: center;
	}
	.timer,
	.timer.event-timer {
		width: calc(100% - 24%);
	}
	.countdown-period span {
		font-size: 10px;
	}
	.latest_event_sermons .event_info {
		float: none;
		width: 100%;
		padding: 0;
	}
	.latest_event_sermons .event_date {
		position: relative;
		top: 0;
		width: auto;
		padding: 7px 15px;
		border: #ccc solid 1px;
		margin: 0 auto 10px;
		float: none;
		width: auto;
		display: inline-block;
		font-size: 16px;
		border-radius: 3px;
	}
	.latest_event_sermons .event_list.event-list-alignemt .event_date {
		left: 0%;
		margin: 10px auto 0px !important;
		border-right: #e8e7e7 solid 1px;
	}
	.latest_event_sermons .event_date span {
		display: inline-block;
		font-size: 16px;
		margin: 0 auto;
	}
	.latest_event_sermons .event_list.event-list-alignemt .event_info,
	.latest_event_sermons .event_list .event_info {
		padding: 25px;
	}
	.latest_event_sermons .event_list .event_date {
		width: auto;
		top: 0;
		padding: 7px 15px;
	}
	.sermons_img,
	.sermons_info_wrap {
		float: none;
		width: 100%;
	}
	.sermons_img {
		margin: 0 auto 20px;
	}
	.post_detail .sermons_inside ul li a {
		padding: 8px 20px;
	}
	.video-wrap-section .video_wrap .video_icon {
		padding: 5% 0;
	}
}
@media (max-width: 496px) {
	/* countdown css */
	.call-action-section .call-content ul li {
		display: inline-block;
		font-size: 0.7em;
		list-style-type: none;
		padding: 1em;
		text-transform: uppercase;
		color: #fff;
	}
	.call-action-section .call-content ul li span {
		display: block;
		font-size: 2rem;
	}
	.timer.event-timer {
		width: calc(100% - 0%);
	}
}
@media (max-width: 456px) {
	/* index testimonal css */
	.about_company .nav-tabs.testi-nav-tabs li a {
		padding: 9px 18px;
	}
}
@media (max-width: 360px) {
	/* index testimonal css */
	.about_company .nav-tabs.testi-nav-tabs li a {
		padding: 9px 12px;
	}
	.newsletter {
		padding: 0 5px;
	}
}
